import React, { useState, useContext } from "react";
import {
  MdMenu,
  MdClose,
  MdExpandMore,
  MdExpandLess,
  MdHomeFilled,
  MdDashboard,
  MdBarChart,
} from "react-icons/md";
import * as Styled from "./style";
import { Link } from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import "./style.css";
import { FilterContext } from "../FilterBar/FilterProvider";
import { ThemeContext } from "../../context/ThemeContext";
import { color as themeColor } from "../../theme/Color";
import { constants } from "../../constants/Constants";

const MobileNavProgram = ({
  program,
  sidebar,
  setSidebar,
  setProduct,
  setProgram,
  regionValue,
  mode,
  setSector,
  sectorValues,
  marketValues,
  setMarket,
}) => {
  const [starredOpen, setStarredOpen] = useState(false);

  const handleProgramClick = (progObj) => {
    setStarredOpen(!starredOpen);
    if (progObj?.products === undefined) {
      setSidebar(false);
      if (regionValue?.programId !== program?.programId) {
        setProgram(program);
      }
    }
  };
  const handleProductClick = (product) => {
    setStarredOpen(!starredOpen);
    setSidebar(false);
    if (regionValue?.programId !== program?.programId) {
      setProgram(program);
    }
    setProduct(product);

    const sectors = sectorValues?.filter(
      ({ productId }) =>
        productId === product.productId &&
        productId !== constants.products.PromoAI &&
        productId !== constants.products.PricingAI
    );
    const sortedSectors =
      product.productId === constants.products.ManufacturingControlTower
        ? sectors.sort((a, b) => a.sectorName.localeCompare(b.sectorName))
        : sectors;

    setSector(sortedSectors?.[0] || {});

    const markets = marketValues.filter(
      (market) =>
        market.productId === sectors[0].productId &&
        market.sectorId === sectors[0].sectorId &&
        market.modelId === sectors[0].modelId
    );
    if (markets && markets.length > 0) {
      setMarket(markets[0]);
    } else {
      setMarket({});
    }
  };

  return (
    <>
      <ListItemButton
        sx={{
          pl: 8,
          background:
            mode === "light"
              ? `${themeColor._F3F7FD}`
              : `${themeColor._272727} `,
          transition: "background 0.3s, box-shadow 0.3s",
          "&:hover": {
            background: mode === "light" ? "CEDBF4" : "#3A3A3A",
            boxShadow: "5px 0px 0px 0px #3387DF inset",
            fontWeight: 700,
          },
        }}
        data-testid={"Program_ListItemButton_" + program.programId}
        onClick={() => handleProgramClick(program)}
      >
        {sidebar && (
          <ListItemText
            primary={program.programName}
            className={`productText ${mode}`}
            data-testid={"Program_ListItemText_" + program.programId}
          />
        )}
      </ListItemButton>
      <Collapse in={starredOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {program?.products?.map((product) => (
            <ListItemButton
              key={product?.productId}
              sx={{
                pl: 12,
                background:
                  mode === "light"
                    ? `${themeColor._F3F7FD}`
                    : `${themeColor._272727} `,
                transition: "background 0.3s, box-shadow 0.3s",
                "&:hover": {
                  background: mode === "light" ? "CEDBF4" : "#3A3A3A",
                  boxShadow: "5px 0px 0px 0px #3387DF inset",
                  fontWeight: 700,
                },
              }}
              data-testid={"Product_ListItemButton_" + product?.productId}
              onClick={() => handleProductClick(product)}
            >
              <ListItemText
                primary={product?.productName}
                className={`productText ${mode}`}
                data-testid={"Product_ListItemText_" + product?.productId}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const SubMenu = ({
  item,
  index,
  sidebar,
  setSidebar,
  activeNav,
  setActiveNav,
}) => {
  const {
    setSelectedProduct,
    setSelectedProgram,
    selectedProgram,
    setSelectedSector,
    setSelectedMarket,
    sectorValues,
    marketValues,
    betaFeatureToggle,
  } = useContext(FilterContext);
  const { mode } = useContext(ThemeContext);
  const handleOpenClose = () => {
    setSidebar(!sidebar);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setActiveNav(index);

    if (!item?.programs) {
      if (sidebar) setSidebar(false);
      return;
    }

    if (item?.basePath) {
      if (sidebar) setSidebar(false);
    }
  };

  const handleNestedClick = () => {
    setOpen(!open);
    setActiveNav(index);
  };
  return (
    <>
      {index === 0 && (
        <Styled.SidebarLink
          onClick={handleOpenClose}
          sidebar={sidebar}
          index={index}
          mode={mode}
          menubtn="true"
          data-accessible={true}
          data-testid="Sidebar_SubMenu_SidebarLink"
        >
          <div data-accessible={true} data-testid="Sidebar_SubMenu_div">
            {sidebar ? <MdClose /> : <MdMenu />}
          </div>
        </Styled.SidebarLink>
      )}
      <Link
        to={item?.basePath ? item?.basePath : item?.path}
        style={{ textDecoration: "none", outline: "none" }}
      >
        <ListItemButton
          to={item?.path || item?.basePath}
          onClick={item?.programs ? handleNestedClick : handleClick}
          className={`listItem ${activeNav === index && "active"}`}
          data-testid={`SubMenu_ListItemButton_${index}`}
        >
          <ListItemIcon
            sx={{
              color:
                activeNav === index
                  ? "#3387DF !important"
                  : "#616161 !important",
            }}
            className="font-24 icon-width"
          >
            {item.icon === "MdHomeFilled" && <MdHomeFilled />}
            {item.icon === "MdDashboard" && <MdDashboard />}
            {betaFeatureToggle && item.icon === "MdBarChart" && <MdBarChart />}
          </ListItemIcon>
          {sidebar && (
            <ListItemText
              primary={item.title}
              className="itemText"
              sx={{
                color:
                  mode === "light"
                    ? `${themeColor._616161}`
                    : `${themeColor._C0C0C0}`,
              }}
              data-testid={`SubMenu_ListItemText_${index}`}
            />
          )}
          {sidebar &&
            item?.programs &&
            (open ? <MdExpandLess /> : <MdExpandMore />)}
        </ListItemButton>
      </Link>
      {sidebar && item?.programs && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item?.programs.map((program) => (
              <MobileNavProgram
                key={program.programId}
                program={program}
                sidebar={sidebar}
                setSidebar={setSidebar}
                setProduct={setSelectedProduct}
                setProgram={setSelectedProgram}
                regionValue={selectedProgram}
                mode={mode}
                setSector={setSelectedSector}
                setMarket={setSelectedMarket}
                sectorValues={sectorValues}
                marketValues={marketValues}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default SubMenu;
