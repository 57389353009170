import styled from "styled-components";
import { color } from "../../theme/Color";

export const SidebarNav = styled.nav`
  background: ${({ mode }) => mode === 'light' ? color._FBFCFE :color._131313};
  box-shadow: ${({ mode }) => (mode === 'light' ? '4px 0px 24px -2px rgba(0, 92, 188, 0.08)' : 'none')};
  width: ${({ sidebar }) => (sidebar ? "280px" : "80px")}; //260px
  display: flex;
  justify-content: center;
  position: fixed;
  top: 64px;
  height: auto;
  bottom: 0;
  z-index: 2;
  left: 0;
  overflow: hidden;
  transition: 0.3s;

  &:hover {
    overflow: auto;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 600px) {
    /* Adjust the breakpoint as needed */
    display: ${({ sidebar }) => (sidebar ? "block" : "none")};
  }
`;

export const SidebarWrap = styled.div`
  width: 100%;
`;

export const SidebarLink = styled.div`
  display: ${({ sidebar, index, menubtn }) =>
    sidebar && index === 0 && menubtn ? "inline-flex" : "flex"};
  color: ${({ mode }) => mode === 'light' ? `#202020 !important` : `${color._616161} `};
  justify-content: ${({ sidebar }) => (sidebar ? "space-between" : "center")};
  align-items: baseline;
  padding: 5px;
  list-style: none;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  border-left: ${({ sidebar }) =>
    sidebar ? "8px solid transparent" : "4px solid transparent"};
  border-right: 4px solid transparent;
  position: relative;
  @media (max-width: 600px) {
    display: none;
  }

  &:hover {
    cursor: pointer;
    & svg,
    & div {
      color: ${({ sidebar }) =>
        sidebar ? "auto" : `${color._003771} !important`};
    }
  }

  & div {
    display: flex;
    align-items: center;
  }

  & svg {
    font-size: 24px;
  }

  &.ActiveIcon {
    border-left: ${({ sidebar }) =>
      sidebar ? "auto" : `4px solid ${color._primary_color}`};

    & svg,
    & div {
      color: ${color._primary_color} !important;
    }
  }
`;

export const SidebarLabel = styled.span`
  margin-left: 16px;
  font-size: 16px;
`;

export const DropdownLink = styled.div`
  background: ${color._F3F7FD};
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${color._616161};
  padding: 14px 14px 14px 48px;

  &:hover {
    cursor: pointer;
  }
`;

export const HoverContainer = styled.div`
  position: fixed;
  left: 78px;
  background-color: ${color._EFF5FC};
  min-width: 250px;
  height: auto;
  filter: drop-shadow(0px 0px 24px rgba(0, 92, 188, 0.08));
  z-index: 10;
  align-items: baseline !important;
  flex-direction: column;
  font-weight: 400;
  margin-top: -20px;

  & a {
    text-decoration: none;
    color: ${color._616161};
    padding: 10px;
    width: 100%;
  }
`;

export const DropdownLinkHover = styled.div`
  &:hover {
    background-color: ${color._D7E5F8};
    cursor: pointer;
  }
`;

export const Sub = styled.div`
  & svg {
    color: ${color._616161} !important;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  display: ${({ sidebar }) => (sidebar ? "flex" : "none")};
  width: 100%;
  flex: 1;
  height: 100%;
  top: 64px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const SidebarTitle = styled.div`
  color: ${color._a0a0a0};
  font-weight: 400;
`;
