import React, { Suspense, useEffect, useState, useCallback, useContext } from "react";
import {
  Routes as Router,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  getScreenResolution,
  setCustomPageName,
  setCustomUserData,
} from "../utils/AppDUtil";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import Dashboard from "../containers/Dashboard/Dashboard";
import { useLayoutNavigation } from "../hooks/layout-navigation-hook";
import OnboardingPage from "../containers/OnboardingPage/OnboardingPage";
import Layout from "../layout/Layout";
import { constants } from "../constants/Constants";
import { helperFunctions } from "../utils/helperFunctions";
import { useOkta } from "hooks/okta-hooks";
import PowerBIDashboard from "containers/PowerBI/PowerBIDashboard";
import { FilterContext } from "../components/FilterBar/FilterProvider";

const Routes = () => {
  const { betaFeatureToggle } = useContext(FilterContext);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const { authenticate } = useOkta();
  const [idleTimer, setIdleTimer] = useState(null);
  const { getUserRoles } = useLayoutNavigation();
  const { debounce } = helperFunctions();
  const getOktaStorageToken = () => {
    oktaAuth.tokenManager.get("accessToken").then((accessToken) => {
      const accessTokenExpiresAt = accessToken?.expiresAt;
      const epochTime = Math.floor(new Date().getTime() / 1000);
      // const epochTime = 1707160000;
      // console.log('OKTA token expires at',accessTokenExpiresAt,'Current Time',epochTime );
      if (accessTokenExpiresAt && accessTokenExpiresAt <= epochTime) {
        localStorage.clear();
        oktaAuth.signOut();
        window.location.href = "/";
      } else {
        getUserRoles();
        return accessToken?.claims;
      }
    });
  };

  const debouncedHandleIdle = useCallback(
    debounce(() => {
      // Set up the idle timer when the user becomes idle
      setIdleTimer(Date.now());
    }, 1000),
    [debounce, setIdleTimer]
  );

  useEffect(() => {
    const checkIdleAndToken = () => {
      // Check if the user is idle
      oktaAuth.tokenManager.get("accessToken").then((accessToken) => {
        const accessTokenExpiresAt = accessToken?.expiresAt;
        const epochTime = Math.floor(new Date().getTime() / 1000);
        if (accessTokenExpiresAt && accessTokenExpiresAt <= epochTime) {
          localStorage.clear();
          oktaAuth.signOut();
          window.location.href = "/";
          // navigate("/login");
        } else if (idleTimer && authState.isAuthenticated) {
          const idleTime = Date.now() - idleTimer;
          if (idleTime >= constants?.IDLE_TIME) {
            // Clear Okta token if the user has been idle for an hour
            localStorage.clear();
            oktaAuth.signOut();
            window.location.href = "/";
            // navigate("/login");
          }
        }
      });
    };

    // Set up event listeners for idle detection
    document.addEventListener("click", debouncedHandleIdle);
    document.addEventListener("wheel", debouncedHandleIdle);
    document.addEventListener("keydown", debouncedHandleIdle);

    // Set up a timer to periodically check idle status and token expiry
    const timerInterval = setInterval(checkIdleAndToken, 60000); // Check every minute

    return () => {
      // Clean up event listeners and timers when the component is unmounted
      document.removeEventListener("click", debouncedHandleIdle);
      document.removeEventListener("wheel", debouncedHandleIdle);
      document.removeEventListener("keydown", debouncedHandleIdle);
      clearInterval(timerInterval);
    };
  }, [idleTimer, authState, oktaAuth]);
  useEffect(() => {
    console.log("AppD: page has been navigated to:", pathname);
    const oktaTokenStorageData = getOktaStorageToken();
    setCustomUserData({
      gpid: oktaTokenStorageData?.gpid || "",
      email: oktaTokenStorageData?.email || "",
      FirstName: oktaTokenStorageData?.FirstName || "",
      LastName: oktaTokenStorageData?.LastName || "",
      screenResolution: getScreenResolution(),
      language: navigator?.language,
    });

    setCustomPageName(pathname);
  }, [pathname, authState, navigate]);

    // Use effect to handle redirection based on betaFeatureToggle
    useEffect(() => {
      if (!betaFeatureToggle && pathname === "/psdlite") {
        navigate("/dashboard");
      }
    }, [betaFeatureToggle, pathname, navigate]);

  // const renderDashboardBasedOnRole = () => {
  //   if (
  //     !authState?.isAuthenticated &&
  //     !JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken
  //   ) {
  //     authenticate();
  //     // return <OnboardingPage />; // User is not authenticated, show the OnboardingPage
  //   }
  //   if (userRoles.includes("Executive User") || userRoles.includes("eu")) {
  //     // User is authenticated and has the role of "Executive User" or "eu"
  //     return (
  //       <Layout>
  //         <ExecutiveTab />
  //       </Layout>
  //     );
  //   }
  //   if (userRoles.includes("Regular User") || userRoles.includes("ru")) {
  //     // User is authenticated and has the role of "Regular User" or "ru"
  //     return (
  //       <Layout>
  //         <Dashboard />
  //       </Layout>
  //     );
  //   }

  //   // Default case: Render nothing if none of the conditions match
  //   return null;
  // };
  const renderDashboardPage = () => {
    if (
      !authState?.isAuthenticated &&
      !JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken
    ) {
      authenticate();
      // return <OnboardingPage hideLoginView={true}/>; // User is not authenticated, show the OnboardingPage
    } else {
        return (
          <Layout>
            <Dashboard />
          </Layout>
        );
      }
  };

  const renderPSDLitePage = () => {
    if (
      !authState?.isAuthenticated &&
      !JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken
    ) {
      authenticate();
      return null; // Prevent rendering until authenticated
    } else if (betaFeatureToggle) {
      return (
        <Layout>
          <PowerBIDashboard />
        </Layout>
      );
    } else {
      // No need for this else branch; handled by the effect above
      return null;
    }
  };

  return (
    <Suspense>
      <Router>
        {/* <Route path="/auth" title="Onboarding" element={<OnboardingPage />} /> */}
        <Route path="/login" title="Login" element={<LoginCallback />} />
        <Route
          path="/logout"
          title="Logout"
          element={<OnboardingPage showLogout={true} />}
        />
        <Route
          path="/dashboard"
          title="Dashboard"
          element={renderDashboardPage()}
        />

        <Route
          path="/psdlite"
          title="PSD Lite Dashboard"
          element={renderPSDLitePage()}
        />

        <Route
          path="/"
          title="ExecutiveOverView"
          exact={true}
          element={renderDashboardPage()} //renderDashboardBasedOnRole
        />
      </Router>
    </Suspense>
  );
};

export default Routes;
